<template>
  <div>
    <div v-if="$getDebug()">
      mw{{ modelValue }}<br />
      ml{{ modelLocal }}<br />
      tfdv{{ textFieldDynamicValue }}<br />
      sel{{ selected.value }}
    </div>
    <v-text-field
      prepend-icon="mdi-home-silo-outline"
      append-inner-icon="mdi-search"
      :label="label"
      :rules="rules"
      v-model="textFieldDynamicValue"
      :loading="loading"
      @click="openDialog"
      @focus="focus = true"
      @blur="focus = false"
      clearable
    />
    <v-dialog v-model="dialog">
      <v-card>
        <v-card-title>{{ label }}</v-card-title>
        <v-card-text>
          <div v-if="$getDebug()">
            mw{{ modelValue }}<br />
            ml{{ modelLocal }}<br />
            tfdv{{ textFieldDynamicValue }}<br />
            sel{{ selected.value }}
          </div>
          <v-row class="row search spacer-md">
            <v-col v-for="(card, index) in filteredSearchCards" :key="index" cols="12" sm="6" md="4" xl="2">
              <component :is="registeredComponents[card.component]" v-bind="card.props" @search="search" />
            </v-col>
          </v-row>
          <v-btn color="primary" v-on:click="search"><span v-html="$t('search')" /></v-btn>
          <v-btn color="secondary" class="margin-left" v-on:click="reset"><span v-html="$t('reset')" /></v-btn>
          <v-data-table-server
            v-model="selected"
            select-strategy="single"
            item-value="localUnitId"
            show-select
            :headers="fields"
            :items-length="totalElements"
            :items="items"
            :fields="fields"
            :loading="loading"
            @update:itemsPerPage="
              (size: number) => {
                localQuery.size = size
              }
            "
            @update:page="
              (page: number) => {
                localQuery.page = page - 1
              }
            "
            @update:sort-by="
              (sort: any) => {
                localQuery.sort = toJavaSort(sort)
              }
            "
            :sort-by="toDTSort(localQuery.sort)"
            :page="localQuery.page + 1"
            :items-per-page="localQuery.size"
            :items-per-page-options="$itemsPerPageOptions"
            density="compact"
          >
            <template v-slot:item.farmName1="{ item }">
              {{ item.farmName1 || item.legalEntityName1 }}
            </template>
            <template v-slot:item.farmName2="{ item }">
              {{ item.farmName2 || item.legalEntityName2 }}
            </template>
            <template v-slot:item.info="{ item }">
              {{ item['info' + $getUpLang()] }}
            </template>
          </v-data-table-server>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script setup lang="ts">
import { apiURL, app } from '@/main'
import { Term } from '@/services/term'
import { showError } from '@/services/axios'
import { ref, shallowRef, reactive, computed, watch, getCurrentInstance, onMounted, nextTick } from 'vue'
import { useSearchContactStore } from '@/store/SearchContactStore'
import { useSearchLocalUnitStore } from '@/store/SearchLocalUnitStore'
import { useSearchFarmStore } from '@/store/SearchFarmStore'
import { useSearchDairyStore } from '@/store/SearchDairyStore'

const props = defineProps({
  modelValue: { type: Number, required: false },
  label: String,
  rules: {
    type: Array,
    required: false,
    default: () => {
      return []
    }
  },
  cards: {
    validator(value: string[]): boolean {
      const validValues = ['searchLocalUnit', 'searchFarm', 'searchContact', 'searchDairy']
      return value.every((card) => validValues.includes(card))
    },
    default: () => {
      return ['searchLocalUnit', 'searchContact', 'searchFarm']
    }
  }
})

const emit = defineEmits(['update:modelValue', 'input'])

const dialog = ref(false)
const loading = ref(false)
const focus = ref(false)
const contact = reactive({ farmName1: '', farmName2: '', legalEntityName1: '', legalEntityName2: '' })
const items = ref([])
const totalElements = ref(0)

const { proxy } = getCurrentInstance()

const fields = reactive([
  {
    title: proxy.$t('masterdata_legalEntities_farmName1'),
    key: 'farmName1',
    sortable: true,
    align: 'left'
  },
  {
    title: proxy.$t('masterdata_legalEntities_farmName2'),
    key: 'farmName2',
    sortable: true,
    align: 'left'
  },
  {
    title: proxy.$t('masterdata_legalEntities_zip'),
    key: 'zip',
    sortable: true,
    align: 'left'
  },
  {
    title: proxy.$t('masterdata_legalEntities_locality'),
    key: 'locality',
    sortable: true,
    align: 'left'
  },
  {
    title: proxy.$t('masterdata_legalEntities_localUnitBerNo'),
    key: 'bur',
    sortable: true,
    align: 'left'
  },
  {
    title: proxy.$t('masterdata_legalEntities_farmTypeName'),
    key: `info`,
    sortable: true,
    align: 'left'
  }
])

const openDialog = () => {
  dialog.value = true
}

//////////////////////////// load data

const localQuery = reactive({
  sort: 'id,asc',
  page: 0,
  size: 10
})
const term = computed(() => {
  return [
    ...(props.cards.includes('searchLocalUnit') ? Term.buildTermItems(useSearchContactStore(), useSearchContactStore().items) : []),
    ...(props.cards.includes('searchFarm') ? Term.buildTermItems(useSearchLocalUnitStore(), useSearchLocalUnitStore().items) : []),
    ...(props.cards.includes('searchContact') ? Term.buildTermItems(useSearchFarmStore(), useSearchFarmStore().items) : []),
    ...(props.cards.includes('searchDairy') ? Term.buildTermItems(useSearchDairyStore(), useSearchDairyStore().items) : [])
  ]
})

const query = computed(() => ({
  ...localQuery,
  term: Term.stringify(term.value)
}))

watch(
  () => localQuery,
  () => {
    load()
  },
  { deep: true }
)

const search = () => {
  load()
}

const load = async () => {
  loading.value = true

  items.value = []
  try {
    const response = await app.axios.get(apiURL + '/masterdata/searchLocalUnit', {
      params: { ...query.value }
    })
    const responseItems = response.data.content
    items.value = Array.isArray(responseItems) ? responseItems : []
    totalElements.value = proxy.checkPage(response.data.totalElements)
  } catch (error) {
    showError(error)
  } finally {
    loading.value = false
  }
}

//////////////////////////// data handling

const selected = computed({
  get() {
    return []
  },
  set(value) {
    modelLocal.value = value[0]
    dialog.value = false
  }
})

const textFieldDynamicValue = computed({
  get() {
    return focus.value ? modelLocal.value : valueAndName.value
  },
  set(value) {
    if (typeof value === 'string') {
      modelLocal.value = value
      contact.farmName1 = ''
      contact.farmName2 = ''
      contact.legalEntityName1 = ''
      contact.legalEntityName2 = ''
    } else {
      modelLocal.value = ''
    }
  }
})

const modelLocal = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    let itemX = items.value.filter((x) => x.localUnitId == value)[0]
    if (typeof value === 'string') {
      emit('input', value)
      emit('update:modelValue', value)
      contact.farmName1 = ''
      contact.farmName2 = ''
      contact.legalEntityName1 = ''
      contact.legalEntityName2 = ''
    } else {
      contact.farmName1 = itemX.farmName1 || ''
      contact.farmName2 = itemX.farmName2 || ''
      contact.legalEntityName1 = itemX.legalEntityName1 || ''
      contact.legalEntityName2 = itemX.legalEntityName2 || ''
      emit('input', value)
      emit('update:modelValue', value)
    }
  }
})

const valueAndName = computed(() => {
  if (props.modelValue == null) return ''
  if (contact.farmName1) {
    return `${props.modelValue} : ${contact.farmName1} ${contact.farmName2}`
  } else if (contact.legalEntityName1) {
    return `${props.modelValue} : ${contact.legalEntityName1} ${contact.legalEntityName2}`
  } else if (!contact.farmName1) return props.modelValue
})

watch(
  () => props.modelValue,
  (newValue) => {
    if (newValue === null) {
      contact.farmName1 = ''
      contact.farmName2 = ''
      contact.legalEntityName1 = ''
      contact.legalEntityName2 = ''
    }
  }
)

//////////////////////////// cards

const searchCards = [
  {
    component: 'search-contact',
    filterKey: 'searchContact',
    importPath: 'searchContact',
    props: { ref: 'search-contact' }
  },
  {
    component: 'search-local-unit',
    filterKey: 'searchLocalUnit',
    importPath: 'searchLocalUnit',
    props: { ref: 'search-local-unit' }
  },
  {
    component: 'search-farm',
    filterKey: 'searchFarm',
    importPath: 'searchFarm',
    props: { ref: 'search-farm' }
  },
  {
    component: 'search-dairy',
    filterKey: 'searchDairy',
    importPath: 'searchDairy',
    props: { ref: 'search-dairy' }
  }
]

const filteredSearchCards = computed(() => {
  return searchCards.filter((card) => props.cards.includes(card.filterKey))
})

const registeredComponents = shallowRef({})

const registerComponents = async () => {
  for (let card of searchCards) {
    if (!registeredComponents.value[card.component]) {
      try {
        const comp = await import(`@/components/searchCards/${card.importPath}.vue`)
        registeredComponents.value[card.component] = comp.default || comp
      } catch (error) {
        console.error(`Error loading component ${card.component}:`, error)
      }
    }
  }
}

const reset = () => {
  items.value = []
  searchCards.forEach((card) => {
    if (card.component && card.props.ref && proxy.$refs[`${card.props.ref}`]) {
      proxy.$refs[`${card.props.ref}`][0].reset()
    }
  })
}

watch(
  () => props.cards,
  () => {
    registerComponents()
  },
  { immediate: true }
)

onMounted(async () => {
  await registerComponents()
})
</script>
